import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Footer from "../components/common/footers/Footer"
import { StaticImage } from "gatsby-plugin-image"

const Index = () => {

  useEffect(() => {
    navigate("/app")
  }, [])


  return (
    <></>
  )
}

export default Index
